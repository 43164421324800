import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';

import { useCommunityContext } from 'components/CommunityContext';
import { SPACE_QUERY } from 'schema/Space/queries';
import { Space } from 'interfaces/space';
import { features } from 'constants/featureFlags';
import WelcomeToSpace from 'components/WelcomeToSpace';
import CallToActionInput from './components/CallToActionInput';
import SpaceInfo from './components/SpaceInfo';

import styles from './CreatePostCallToAction.module.scss';

const cx = classNames.bind(styles);

interface CreatePostCallToActionProps {
  ariaLabel?: string;
  dataQA?: string;
  inputId?: string;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setSpaceName?: Dispatch<SetStateAction<string>>;
  placeholderText?: string;
}

const CreatePostCallToAction: React.FC<CreatePostCallToActionProps> = ({
  ariaLabel,
  dataQA,
  inputId,
  isModalOpen,
  setIsModalOpen,
  setSpaceName,
  placeholderText,
}) => {
  const { t } = useTranslation();
  const { community, featureFlags } = useCommunityContext();
  const router = useRouter();
  const { spaceId } = router.query;
  const isCoursesToCommunitiesEnabled =
    featureFlags[features.COURSES_TO_COMMUNITIES];

  let currentSpace = {} as Space;

  const { data } = useQuery(SPACE_QUERY, {
    variables: {
      communityId: community.id,
      spaceId,
      isCoursesToCommunitiesEnabled,
    },
    notifyOnNetworkStatusChange: true,
    skip: !spaceId,
  });

  if (data?.community) {
    currentSpace = data.community.communitySpace;
  }

  const showCallToAction =
    !!currentSpace.posts?.totalCount || isModalOpen || !spaceId;

  useEffect(() => {
    if (currentSpace) setSpaceName?.(currentSpace.name);
  }, [currentSpace]);

  return (
    <>
      <div className={cx('call-to-action__wrapper')}>
        <SpaceInfo currentSpace={currentSpace} />
        {spaceId && <WelcomeToSpace />}

        {showCallToAction && (
          <CallToActionInput
            ariaLabel={
              ariaLabel ||
              t(
                'components-createPostCallToAction-write_a_post_placeholder_text',
                'Write a post in {{space}}...',
                {
                  space: currentSpace.name,
                }
              )
            }
            currentSpace={currentSpace}
            dataQA={dataQA || 'space-create-post__textfield'}
            inputId={inputId || 'space-create-post__textfield-id'}
            isModalOpen={isModalOpen}
            placeholderText={
              placeholderText ||
              t(
                'components-createPostCallToAction-write_a_post_placeholder_text',
                'Write a post in {{space}}...',
                {
                  space: currentSpace.name,
                }
              )
            }
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </>
  );
};

export default CreatePostCallToAction;

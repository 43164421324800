import React, {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
} from 'react';
import { Surface, Input } from '@thinkific/toga-react';
import classNames from 'classnames/bind';
import { useMutation } from '@apollo/client';

import { useCommunityContext } from 'components/CommunityContext';
import UserAvatar from 'components/UserAvatar';
import { CREATE_POST_MUTATION } from 'schema/Post/mutations';

import CreateNewPostModal from 'components/CreateNewPost/CreateNewPostModal';
import { Space } from 'interfaces/space';
import { eventNames } from 'constants/eventNames';

import { useTranslation } from 'react-i18next';
import useSlowRequestHandler from 'hooks/useSlowRequestHandler';
import styles from '../CreatePostCallToAction.module.scss';

const cx = classNames.bind(styles);

interface CallToActionInputProps {
  ariaLabel: string;
  currentSpace?: Space;
  dataQA: string;
  inputId: string;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  placeholderText: string;
}

const CallToActionInput: React.FC<CallToActionInputProps> = ({
  ariaLabel,
  currentSpace,
  dataQA,
  inputId,
  isModalOpen,
  setIsModalOpen,
  placeholderText,
}) => {
  const { t } = useTranslation();
  const [createNewPostMutation, { loading }] = useMutation(
    CREATE_POST_MUTATION
  );

  useSlowRequestHandler(
    loading,
    t(
      'components-callToActionInput-posts_slow_to_publish_toast',
      'This post is taking longer to publish than usual. You can close the modal and refresh the page. Your post will appear soon.'
    )
  );

  const { community, trackEvent, currentUser } = useCommunityContext();
  const { id, avatarUrl, fullName } = currentUser;

  const srcSet = `${avatarUrl}?width=40 1x, ${avatarUrl}?width=40&dpr=2 2x, ${avatarUrl}?width=40&dpr=3 3x`;

  const createNewPostProps = {
    closeModal: handleOnClick,
    communityId: community.id,
    createNewPostMutation,
    hasAutoFocus: true,
    isLoading: loading,
    isOpen: isModalOpen,
    currentSpace,
    primaryColor: community.primaryColor,
    s3UploadPath: community.s3UploadPath,
    trackEvent,
  };

  useEffect(() => {
    const modalOpen = PubSub.subscribe(eventNames.OPEN_POST_MODAL, () =>
      setIsModalOpen(true)
    );

    return () => {
      PubSub.unsubscribe(modalOpen);
    };
  }, []);

  function handleOnClick() {
    document.body.style.overflow = isModalOpen ? 'visible' : 'hidden';
    setIsModalOpen(!isModalOpen);

    const input = document.querySelector<HTMLElement>(
      '#space-create-post__textfield-id'
    );
    input?.blur();
  }

  function handleToggleModal(event: KeyboardEvent) {
    if (
      event?.key !== 'Tab' &&
      event?.key !== 'Shift' &&
      event?.key !== 'Escape'
    ) {
      handleOnClick();
    }
  }

  return (
    <>
      <Surface className={cx('call-to-action__container')}>
        <div className={cx('call-to-action__avatar-wrapper')}>
          <UserAvatar
            avatarUrl={avatarUrl}
            className={cx('call-to-action__avatar-container')}
            size="sm"
            srcSet={srcSet}
            userId={id}
            username={fullName || ''}
            shouldOpenProfile
          />
        </div>

        <Input
          ariaLabel={ariaLabel}
          extraClasses={cx('call-to-action__input')}
          extraProps={{
            'data-qa': dataQA,
            id: inputId,
            onKeyDown: handleToggleModal,
            onClick: handleOnClick,
          }}
          placeholder={placeholderText}
        />
      </Surface>
      {isModalOpen && <CreateNewPostModal {...createNewPostProps} />}
    </>
  );
};

export default CallToActionInput;

import React from 'react';
import { useRouter } from 'next/router';
import { IconButton, Tooltip, Icon } from '@thinkific/toga-react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { goToEditSpace } from 'utils/routerHelpers';
import { useCommunityContext } from 'components/CommunityContext';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_MD } from 'utils/constants';
import { Space } from 'interfaces/space';
import { getIconNameByStoredValue } from 'helpers/spaceHelpers';
import { isAdmin } from 'utils/userHelpers';

import { features } from 'constants/featureFlags';
import styles from '../CreatePostCallToAction.module.scss';

const cx = classNames.bind(styles);

interface SpaceInfoProps {
  currentSpace: Space;
}

const SpaceInfo: React.FC<SpaceInfoProps> = ({ currentSpace }) => {
  const { t } = useTranslation();
  const { community, currentUser, featureFlags } = useCommunityContext();
  const router = useRouter();
  const { width } = useWindowSize();
  const { spaceId } = router.query;
  const isMobile = width < BREAKPOINT_MD;
  const isExclusiveSpacesEnabled = featureFlags[features.EXCLUSIVE_SPACES];
  const isExclusive = isExclusiveSpacesEnabled && !!currentSpace.isExclusive;
  const { name } = currentSpace;
  const spaceName = isExclusive
    ? t(
        'components-spaceInfo-exclusive_space_title',
        '{{spaceName}} (Exclusive Space)',
        { spaceName: name }
      )
    : name;

  return (
    <>
      {spaceId && currentSpace && (
        <div className={cx('call-to-action__wrapper')}>
          {!isMobile && (
            <div className={cx('call-to-action__header')}>
              <span className={cx('call-to-action__space')}>
                <Icon
                  color={community.primaryColor}
                  name={getIconNameByStoredValue(currentSpace?.iconName)}
                />
                {spaceName}
              </span>
              {isAdmin(currentUser) && (
                <Tooltip title={t('pages-edit_space_title', 'Space Settings')}>
                  <IconButton
                    appearance="utility"
                    aria-label={t('Click to edit the Space information')}
                    className={cx('call-to-action__header-button')}
                    data-qa="settings-gear-icon"
                    name="cog"
                    title={t('Click to edit the Space information')}
                    onClick={() =>
                      goToEditSpace(router, spaceId as string, community.id)
                    }
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SpaceInfo;

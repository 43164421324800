import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { toaster } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';
import { MutationFunction } from '@apollo/client';

import { SCREEN_PHONE, EVENT_POST_TYPES, SUCCESS_TOAST } from 'utils/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import Modal from 'koba/components/Modal';
import { Space } from 'interfaces/space';
import { features } from 'constants/featureFlags';
import { genericToastAlert } from 'utils/toastHelpers';
import { sanitizeAttachments } from 'helpers/postHelpers';
import PostForm from 'components/Forms/Post';
import { Post } from 'schema/Post/types';
import { useCommunityContext } from 'components/CommunityContext';
import { CreatePostMutationVariables } from '__generated__/graphql/legacy/graphql';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { TrackEvent } from 'types/analytics';

import styles from './CreateNewPost.module.scss';

export const MAX_TITLE_LENGTH = 124;

const cx = classNames.bind(styles);

interface CreateNewPostModalProps {
  closeModal(event?: Event): void;
  communityId: string;
  currentSpace?: Space;
  createNewPostMutation: MutationFunction;
  hasAutoFocus?: boolean;
  isLoading: boolean;
  primaryColor: string;
  s3UploadPath: string;
  trackEvent: TrackEvent;
}

const CreateNewPostModal: React.FC<CreateNewPostModalProps> = ({
  closeModal,
  communityId,
  currentSpace,
  createNewPostMutation,
  hasAutoFocus,
  isLoading,
  primaryColor,
  s3UploadPath,
  trackEvent,
}) => {
  const { t } = useTranslation();
  const { featureFlags } = useCommunityContext();
  const isDebuggingEnabled = featureFlags[features.DEV_COMMUNITIES_DEBUGGING];
  const { width } = useWindowSize();
  const isPhone = width < SCREEN_PHONE;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [usersMentioned, setUsersMentioned] = useState<string[]>([]);

  useEffect(() => {
    // Tracking the post modal to compare against
    // creation events for drop-off.
    trackEvent(TrackingEvent.POST_MODAL_OPENED);
  }, []);

  const handleSubmit = async (data: Post) => {
    setIsSubmitting(true);
    const startTime = new Date().getTime();

    const postInput = data.attachment
      ? {
          ...data.attachment,
          altText: data.attachmentAltText,
        }
      : null;

    const variables: CreatePostMutationVariables = {
      discussionableId: communityId,
      title: data.title,
      content: data.content,
      postAttachment: sanitizeAttachments(postInput),
    };

    variables.spaceId = currentSpace?.id || data?.spaceId;

    if (usersMentioned?.length) {
      variables.mentionedUserIds = usersMentioned;
    }

    const {
      data: createPostData,
      errors: createPostError,
    } = await createNewPostMutation({
      variables,
      refetchQueries: ['PostList', 'CommunitySpace'], // Note that specifying the name only works if the query is 'active'
    });

    const endTime = new Date().getTime();

    if (isDebuggingEnabled) {
      const createPostDuration = endTime - startTime;
      if (createPostDuration > 5000) {
        window?.airbrake?.notify({
          error: 'Create Post Exceeded 5 seconds',
          params: {
            duration: createPostDuration,
          },
          context: { severity: 'warning', component: 'CreateNewPostModal' },
        });
      }
    }

    const { ATTACHMENT, POST_TYPE, HAS_MENTION } = TrackingProperty;

    trackEvent(TrackingEvent.POST_CREATED, {
      [ATTACHMENT]: data.attachment ? data.attachment?.attachmentType : null,
      [POST_TYPE]: EVENT_POST_TYPES.POST,
      [HAS_MENTION]: !!usersMentioned?.length,
    });

    const userErrors = createPostData?.createPost?.userErrors;
    if (!userErrors?.length && !createPostError?.length) {
      toaster.notice(t(SUCCESS_TOAST.CREATE_POST));
      closeModal();
      window.scrollTo(0, 0);
    } else {
      if (isDebuggingEnabled) {
        window?.airbrake?.notify({
          error: 'Create Post Error',
          params: {
            userErrors,
            createPostError,
          },
          context: { severity: 'error', component: 'CreateNewPostModal' },
        });
      }
      genericToastAlert();
    }
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    if (isFormDirty && !isSubmitting) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('Are you sure you want to discard your post?'))) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      closeModal={handleCloseModal}
      dataQA="modal__create-new-post"
      modalBodyClasses={cx('modal__create-new-post', {
        mobile: isPhone,
      })}
      title={
        currentSpace?.name
          ? t(`Create new post in {{spaceName}}`, {
              spaceName: currentSpace.name,
            })
          : t('Create new post')
      }
      isOpen
    >
      <PostForm
        closeModal={handleCloseModal}
        hasAutoFocus={hasAutoFocus}
        initValues={{
          title: '',
          content: '',
          spaceId: currentSpace?.id ?? '',
          attachment: null,
          attachmentAltText: '',
        }}
        isLoading={isLoading}
        primaryColor={primaryColor}
        s3UploadPath={s3UploadPath}
        setIsFormDirty={setIsFormDirty}
        setUsersMentioned={setUsersMentioned}
        submitLabel={t('Create post')}
        usersMentioned={usersMentioned}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

CreateNewPostModal.defaultProps = {
  hasAutoFocus: false,
};

export default CreateNewPostModal;

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommunityContext } from 'components/CommunityContext';
import Button from 'koba/components/Button';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import EmptyStateCard from 'components/InfoCard/EmptyStateCard/EmptyStateCard';

interface CreatePostCardProps {
  title: string;
  content: ReactNode;
  imageSrc: string;
  dataQA?: string;
  onClick: () => void;
}

const CreatePostCard: React.FC<CreatePostCardProps> = ({
  onClick,
  title,
  content,
  dataQA = 'create-post-card',
  imageSrc,
}) => {
  const { t } = useTranslation();
  const { community, trackEvent } = useCommunityContext();

  const handleClickOnPostCTA = () => {
    trackEvent(TrackingEvent.POST_MODAL_OPENED, {
      [TrackingProperty.COMPONENT]: 'CreatePostCard',
    });
    onClick();
  };

  const createPostCardButton = (
    <Button
      appearance="default"
      ariaLabel={t(
        'components-infoCard-createPostCard-call_to_action_aria_label',
        'Click to create a post'
      )}
      color={community.primaryColor}
      data-qa={`${dataQA}__button-create`}
      key="create-post-card__button-create"
      onClick={handleClickOnPostCTA}
    >
      {t(
        'components-infoCard-createPostCard-create_post_button_text',
        'Create post'
      )}
    </Button>
  );

  return (
    <EmptyStateCard
      content={content}
      dataQA={dataQA}
      emptyStateAction={createPostCardButton}
      imageSrc={imageSrc}
      title={title}
      hasAdminSection
    />
  );
};

export default CreatePostCard;

import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Col, P, Row } from '@thinkific/toga-react';

import InfoCard from 'components/InfoCard';
import Image from 'components/Image';
import AdminOnlyIndicator from 'components/AdminOnlyIndicator';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import { WELCOME_TO_SPACES } from 'utils/constants';
import { useCommunityContext } from 'components/CommunityContext';
import { isAdmin } from 'utils/userHelpers';

import styles from './WelcomeToSpace.module.scss';

const cx = classNames.bind(styles);

const WelcomeToSpace: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useCommunityContext();

  if (!isAdmin(currentUser)) return null;

  return (
    <div className={cx('mb-2')}>
      <InfoCard
        dataQA="welcome-to-spaces__card"
        localStorageKey="hideWelcomeToSpaces"
        title={t(
          'components-welcomeToSpace-welcome_to_space',
          'Welcome to Spaces'
        )}
        isDismissable
      >
        <Row className={cx('welcome-to-space__wrapper')}>
          <Col lgColumns={6}>
            <P className={cx('welcome-to-space__description')}>
              {t(
                'components-welcomeToSpace-description',
                'A Space is like a virtual room inside your Community. You can use Spaces to organize discussion topics, create groups, and encourage your members to ask questions and share their insights.'
              )}
            </P>
            <P className={cx('welcome-to-space__hint')}>
              {t(
                'components-welcomeToSpace-hint',
                'You can customize a Space’s description and image by going to Settings on the top right.'
              )}
            </P>
          </Col>
          <Col lgColumns={4}>
            <VisibilityCloak hideMD>
              <Image
                className={cx('welcome-to-space__image')}
                height={200}
                src={WELCOME_TO_SPACES}
                width={213}
              />
            </VisibilityCloak>
          </Col>
        </Row>
        <Row>
          <Col smColumns={10}>
            <VisibilityCloak admins>
              <AdminOnlyIndicator />
            </VisibilityCloak>
          </Col>
        </Row>
      </InfoCard>
    </div>
  );
};

export default WelcomeToSpace;

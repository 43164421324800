import { useState, useEffect } from 'react';
import { toaster } from '@thinkific/toga-react';

type UseSlowRequestHandler = (isLoading: boolean, message: string) => void;

const useSlowRequestHandler: UseSlowRequestHandler = (isLoading, message) => {
  const [currentTimerId, setTimer] = useState<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (isLoading === true) {
      const timerId: ReturnType<typeof setTimeout> = setTimeout(() => {
        if (isLoading === true) {
          toaster.info(message, {
            autoClose: 10000,
          });
        }
      }, 4000);
      setTimer(timerId);
    } else if (currentTimerId != null) {
      clearTimeout(currentTimerId);
    }
    return () => {
      if (currentTimerId) {
        clearTimeout(currentTimerId);
      }
    };
  }, [isLoading]);
};

export default useSlowRequestHandler;
